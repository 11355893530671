@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.tecnico-nome {
  font-family: 'Roboto', sans-serif; /* Fonte mais clean */
  font-weight: bold; /* Negrito */
  font-size: 1.2rem; /* Tamanho padrão */
}

@media (max-width: 600px) {
  .tecnico-nome {
    font-size: 1rem; /* Reduz o tamanho em telas menores */
    font-weight: bold; /* Mantém o negrito em mobile */
  }
}
