body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 8px; /* Largura do scroll */
}

body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Fundo do track */
}

body::-webkit-scrollbar-thumb {
  background: #DAD7D7; /* Cor do thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background: #DAD7D7; /* Cor ao passar o mouse */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiDrawer-paper::-webkit-scrollbar {
  width: 6px; /* Largura da barra de rolagem */
}

.MuiDrawer-paper::-webkit-scrollbar-track {
  background: #209BDE; /* Cor do fundo da barra de rolagem */
}

.MuiDrawer-paper::-webkit-scrollbar-thumb {
  background: #F4F4F4; /* Cor da barra de rolagem */
  border-radius: 10px; /* Arredondamento */
}

.MuiDrawer-paper::-webkit-scrollbar-thumb:hover {
  background: #DAD7D7; /* Cor da barra de rolagem ao passar o mouse */
}