/* ComponentStyles.css */

.input-label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .date-input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 60%;
    box-sizing: border-box;
  }