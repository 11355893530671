@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 8px; /* Largura do scroll */
}

body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Fundo do track */
}

body::-webkit-scrollbar-thumb {
  background: #DAD7D7; /* Cor do thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background: #DAD7D7; /* Cor ao passar o mouse */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiDrawer-paper::-webkit-scrollbar {
  width: 6px; /* Largura da barra de rolagem */
}

.MuiDrawer-paper::-webkit-scrollbar-track {
  background: #209BDE; /* Cor do fundo da barra de rolagem */
}

.MuiDrawer-paper::-webkit-scrollbar-thumb {
  background: #F4F4F4; /* Cor da barra de rolagem */
  border-radius: 10px; /* Arredondamento */
}

.MuiDrawer-paper::-webkit-scrollbar-thumb:hover {
  background: #DAD7D7; /* Cor da barra de rolagem ao passar o mouse */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@charset "UTF-8";
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

.rbc-day-bg.event-day {
  display: none;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: #999999;
}

.rbc-off-range-bg {
  background: #e6e6e6;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  min-height: 0;
  border-bottom: 1px solid #ddd;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-button-link {
  color: inherit;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
}

.rbc-row-content {
  position: relative;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: #eaf6ff;
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group, .rbc-btn-group + button {
  margin-left: 10px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
  height: 20px;
}
.rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #265985;
}
.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #3b99fc;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  color: #3174ad;
  display: none !important;
}
.rbc-show-more:hover, .rbc-show-more:focus {
  color: #265985;
}

.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  height: 301px;
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 20%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
}

.rbc-date-cell {
  flex: 1 1;
  min-width: 12;
  padding-right: 5px;
  text-align: center;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
  width: 10%;
}
.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
  width: 10%;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  overflow: hidden;
}
.rbc-row-segment-past {
  /* Estilos personalizados para a linha de dias passados */
  /* Por exemplo, altere a cor do texto ou a cor de fundo */
  color: #000;
}
.rbc-day-bg {
  flex: 1 0;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #ADD8E6;
  display: flex;
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #ADD8E6;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ADD8E6;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #ADD8E6;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ADD8E6;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}

.rbc-agenda-time-cell {
  width: 10%;
}

.rbc-agenda-date-cell {
  width: 10%;
  font-weight: bold;
}
.rbc-agenda-event-cell {
  width: 80%;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}
.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #ddd;
  z-index: 10;
  margin-right: -1px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0;
  flex-basis: 0px;
}
.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1;
  flex-basis: 0 px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
  text-align: right;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #ddd;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #000;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

/*# sourceMappingURL=react-big-calendar.css.map */
.tecnico-nome {
  font-family: 'Roboto', sans-serif; /* Fonte mais clean */
  font-weight: bold; /* Negrito */
  font-size: 1.2rem; /* Tamanho padrão */
}

@media (max-width: 600px) {
  .tecnico-nome {
    font-size: 1rem; /* Reduz o tamanho em telas menores */
    font-weight: bold; /* Mantém o negrito em mobile */
  }
}

/* ComponentStyles.css */

.input-label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .date-input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 60%;
    box-sizing: border-box;
  }
@font-face {
    font-family: 'Mont';
    src: '../../public/fonts/Mont-Regular.ttf' format('truetype');
}

body {
    background-color: #EFF2F8 !important;
    font-family: 'Mont';
}
